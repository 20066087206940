import * as React from "react"
import Layout from "../../components/Layout"
import BookingOnline from "../../components/BookingOnline"
import HeroBanner from "../../components/HeroBanner"
import Aos from "aos"
import BackToTop from "../../components/BackToTop"
import { graphql } from "gatsby"
import MenusSlider from "../../components/Sliders/MenusSlider"
import Seo from "../../components/Seo"
// import DineMenu from "../components/dineDrink/menu"
import styled from "styled-components"
import useWindowSize from "../../../hooks/useWindowSize"
import { PortableText } from "@portabletext/react"
import CommonLink from "../../components/CommonLink"

const Restaurant = ({ data }) => {
  const {
    title,
    _rawDescription,
    heroImg,
    logo,
    bistroBar,
    menu,
    privateParty,
  } = data?.sanityPages?.dineDrinkPage
  const [activeMenu, setActiveMenu] = React.useState(menu?.menuList[0]?.name)
  const [showPopup, setShowPopup] = React.useState(false)
  const [menuLink, setMenuLink] = React.useState(menu?.menuList[0]?.pdfLink)

  const width = useWindowSize()

  const [activeIndex, setActiveIndex] = React.useState(0)

  React.useEffect(() => {
    Aos.init()
  }, [])

  return (
    <>
      <Seo
        title={"Dine and Drinks Page"}
        description={
          "In this page user can see all available menus of foods and drinks."
        }
      />
      <Layout>
        <div className="header_bottom">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <HeroBanner
          bannerImg={heroImg?.asset?.gatsbyImageData}
          pageClass={"dine__drinks"}
        />
        <div className="menus__wrapper">
          <div className="bar__section dine__drinks">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="bar_wrap">
                    <div className="bar_content">
                      <h3
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-duration="1000"
                      >
                        {title}
                      </h3>
                      <div
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-duration="1100"
                      >
                        <PortableText value={_rawDescription} />
                      </div>
                    </div>
                    <div className="container-fluid" style={{ padding: "0px" }}>
                      <div className="row">
                        <div
                          className={`col ${width.width < 740 && "order-3"}`}
                        >
                          <div className="bar_list">
                            <h4
                              data-aos="fade-up"
                              data-aos-delay="100"
                              data-aos-duration="1000"
                            >
                              {bistroBar?.title}
                            </h4>
                            <ul>
                              {bistroBar?.timeList.map((item, index) => (
                                <li
                                  key={index}
                                  data-aos="fade-up"
                                  data-aos-delay={`${50 + index * 50}`}
                                  data-aos-duration={`${1000 + index * 100}`}
                                >
                                  <a href="#">{item}</a>
                                </li>
                              ))}
                              <div
                                className="mt-3"
                                data-aos="fade-up"
                                data-aos-delay={`${
                                  50 + 50 * bistroBar.timeList.length
                                }`}
                                data-aos-duration={`${
                                  1000 + bistroBar.timeList.length * 100
                                }`}
                              >
                                <CommonLink
                                  link={bistroBar?.bookLink.path}
                                  type={bistroBar?.bookLink.path}
                                  className="bistro__book__button"
                                >
                                  {bistroBar?.bookLink.label}
                                </CommonLink>
                              </div>
                            </ul>
                          </div>
                        </div>
                        {width.width < 740 && <div className="w-100 order-2" />}
                        <div
                          className={`col d-flex justify-content-center ${
                            width.width < 740 && "order-1 mb-5"
                          }`}
                        >
                          <img
                            src={logo?.asset?.url}
                            style={{ objectFit: "contain" }}
                            width="364.8px"
                            height="295.8px"
                            data-aos="fade-up"
                            data-aos-delay="100"
                            data-aos-duration="1000"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="deluxe__slider__serction">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="menus_blk">
                    <h4
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1000"
                    >
                      {menu?.title}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1100"
                    >
                      {menu?.subHeading}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="menus_tab">
                    <div className="menus_box">
                      <ul className="nav menus_nav" id="myTab" role="tablist">
                        {menu?.menuList?.map((item, index) => (
                          <li
                            className="nav-item"
                            role="presentation"
                            key={index}
                            data-aos="fade-up"
                            data-aos-delay={`${50 + index * 50}`}
                            data-aos-duration={`${1000 + index * 100}`}
                          >
                            <a
                              className={
                                activeIndex === index
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target={`#${item?.name}`}
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              onClick={() => {
                                setActiveMenu(item?.name)
                                setMenuLink(item?.pdfLink)
                                setShowPopup(true)
                                setActiveIndex(index)
                              }}
                            >
                              {item?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div class="tab-content" id="myTabContent">
                      {menu?.menuList?.map((item, index) => (
                        <>
                          {activeMenu === item?.name && (
                            <div
                              class="tab-pane fade show active"
                              id={`${item?.name}`}
                              role="tabpanel"
                              aria-labelledby="home-tab"
                              key={index}
                            >
                              {/* <DineMenu items={item.menusItems} /> */}
                              <div class="row">
                                <div class="col-lg-12">
                                  <div className="menu__images">
                                    <MenusSlider data={item?.images} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div classname="dine_parties">
            <div className="container">
              <div className="row">
                <h3
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="dine_parties_title"
                >
                  {privateParty?.title}
                </h3>
                <div
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                  className="dine_parties_content"
                >
                  <PortableText value={privateParty?._rawDescription} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {showPopup && (
        <PopupWindow>
          <button onClick={() => setShowPopup(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="content-wrapper">
            <div>
              <iframe
                title="Noyo Menus"
                src={menuLink}
                height="100%"
                width="100%"
              ></iframe>
            </div>
          </div>
        </PopupWindow>
      )}
    </>
  )
}
export default Restaurant

const ImageWrapper = styled.div`
  background: ${props => `url(${props.bannerImg})`};
  background-size: cover;
  width: ${props => props.width}px;
  height: ${props => props.width / 2}px;
  background-repeat: no-repeat;
  margin-top: 123px;
  &.home {
    margin-top: 0px;
  }
  &.history {
    margin-top: 0px;
  }
  &.single__offer {
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    &.single__room__page {
      margin-top: 0px;
    }
    &.stay__page {
      margin-top: 0px;
    }
    &.dine__drinks {
      margin-top: 88px;
    }
  }
`

const PopupWindow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  & button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    outline: none;
    border: none;
    color: white;
    & svg {
      height: 32px;
      width: 32px;
    }
  }
  & div.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    & div {
      max-width: 80%;
      width: 100%;
      height: 80%;
    }
  }
`

export const dineData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      dineDrinkPage {
        title
        _rawDescription
        heroImg {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        logo {
          asset {
            url
          }
        }
        bistroBar {
          title
          timeList
          bookLink {
            label
            path
            type
          }
        }
        menu {
          title
          subHeading
          menuList {
            name
            pdfLink
            images {
              asset {
                gatsbyImageData(fit: FILLMAX, formats: WEBP)
              }
            }
          }
        }
        privateParty {
          title
          _rawDescription
        }
      }
    }
  }
`
