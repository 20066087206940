import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useWindowSize from "../../../../hooks/useWindowSize"
import RightArrow from "../../SliderArrows/RightArrows"
import { GatsbyImage } from "gatsby-plugin-image"

const MenusSlider = ({ data }) => {
  const { width } = useWindowSize()
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  console.log(data)
  return (
    <>
      {width > 600 ? (
        <Slider {...settings} nextArrow={width < 601 ? null : <RightArrow />}>
          {data?.map((item, index) => (
            <div
              className="deluxe_slider_items tr px-2 my-5"
              data-aos="fade-up"
              data-aos-delay={`${50 + index * 50}`}
              data-aos-duration={`${1000 + index * 100}`}
              key={index}
            >
              <GatsbyImage
                image={item?.asset?.gatsbyImageData}
                alt="Thum"
                className="img_Thumb"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
          ))}
        </Slider>
      ) : (
        <>
          {data?.map((item, index) => (
            <div
              className="deluxe_slider_items tr px-2 my-5"
              data-aos="fade-up"
              data-aos-delay={`${50 + index * 50}`}
              data-aos-duration={`${1000 + index * 100}`}
              key={index}
            >
              <GatsbyImage
                image={item?.asset?.gatsbyImageData}
                alt="Thum"
                className="img_Thumb"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
          ))}
          <div
            className="menu__items__more"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="2000"
          >
            <a href="#">See More</a>
          </div>
        </>
      )}
    </>
  )
}

export default MenusSlider
